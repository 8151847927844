import React, { useContext, useEffect, useRef, useState } from 'react'
import { AppContext } from '../../common/GlobalContext'
import CONS from '../../common/Constants'

/**
 * Importamos los componentes externos necesarios para
 * el funcionamiento de la página.
 */
import { toast } from 'react-toastify';
import md5 from 'md5'

/**
 * Importamos los componentes internos necesarios para
 * el funcionamiento de la página.
 */
import InputComponent from './components/InputComponent'
import LoginCard from './components/LoginCard'

/**
 * Importamos archivos estaticos.
 */
//import bg from '../../assets/img/bg_login.jpg'
import bg from '../../assets/img/crux-australis.jpg'
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom';


export default function LoginPage() {

    /**
     * Con useRef podemos acceder a los valores y eventos de los
     * elementos de input que configuremos.
     * 
     * TODO:
     *     Sería bueno sanitizar los inputs
     *     antes de trabajar con ellos.
     *   
     * @param userInput: String nombre de usuario
     * @param userPass: String contrraseña
    */

    const userInput = useRef()
    const userPass = useRef()

    // Cargamos el estado global de la app
    const [state, setState] = useContext(AppContext)

    // para poder cambiarnos de url
    const history = useHistory()

    // Logica para el inicio de sesión.
    const doLogin = async () => {

        const flattenUsuarios = []

        if (!state.usuarios) {
            // No hay conexion con servicios!
            toast.error('No hay conexion con los servidores')
            return
        }
        const usuarios = state.usuarios
        await usuarios.map(usuario => flattenUsuarios[usuario.username] = usuario)

        const password = userPass.current.value ? md5(userPass.current.value).toString().toUpperCase() : false
        const loginAtempt = userInput.current.value ? flattenUsuarios[userInput.current.value] : false

        if (!loginAtempt || !password) {
            // setState({ ...state, modal: false })
            toast.error('Usuario o contraseña incorrectos.')
            return
        }

        if (loginAtempt[""] === password) {
            // setState({ ...state, modal: false })
            console.log('usuario valido')

            setState({ ...state, users_session: { username: userInput.current.value } })
            history.push('/configurar-tramo')

        } else {
            // setState({ ...state, modal: false })
            toast.error('Usuario o contraseña incorrectos.')
            return
        }
    }


    // Cargamos el listado de usaurios desde la api
    useEffect(() => {

        // Obtener usuarios desde API
        const getUsers = () => {
            const url = CONS.apiCruce + '/api/Users'
            //console.log(fetch(url))

            var response = fetch(url)
                .then(res => res.json())
                .then(data => setState({ usuarios: data }))
                .catch(error => {
                    console.log(error);
                  });
            return response;
        }

        // nos aseguramos que el efecto se ejecute sólo una vez.
        if (!state.usuarios) {
            getUsers()
        }

    }, [state, setState])





    return (

        <div className="relative z-0 w-full h-full pt-10">
            <LoginCard>

                <InputComponent type="text" ref={userInput} name="user" placeholder="Usuario">
                    <svg className="w-6 h-6 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                    </svg>
                </InputComponent>

                <InputComponent type="password" ref={userPass} name="pass" placeholder="Contraseña">
                    <svg className="w-6 h-6 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z" />
                    </svg>
                </InputComponent>

                <div className="w-full block mb-5">
                    <button onClick={doLogin} className="w-full rounded block bg-blue-800 uppercase text-white py-4 transition-colors hover:bg-blue-900" style={{ backgroundColor: "#0684c6" }}>INGRESAR</button>
                </div>

                <div>
                    {/* <small>Estás ejecutando esta aplicación en modo <b>{process.env.NODE_ENV} Version 01122024</b>.</small> */}
                    <small><b>Version 01122024</b>.</small>
                    <form>
                        <input type="hidden" defaultValue={process.env.REACT_APP_NOT_SECRET_CODE} />
                    </form>
                    </div>


            </LoginCard>

            <div className="absolute object-cover w-full h-full top-0 left-0 z-0">
                <img className="object-cover w-full h-full" src={bg} alt="Transbordadora Austral Broom" />
            </div>

        </div >
    )
}

