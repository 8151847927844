import React, { useContext, useEffect, useRef, useState } from 'react'
import { AppContext } from '../../common/GlobalContext'
import CONS from '../../common/Constants'
import LoginCard from './components/LoginCard'
import InputComponent from './components/InputComponent'
import bg from '../../assets/img/crux-australis.jpg'
import './TestPage.css';
import moment from 'moment';

export default function TestPage() {
    const [history, setHistory] = useState("")

    const intervalRef = useRef(null);

    function consultarBD() {
        let statusCode;
        const formattedDate = moment().format('YYYY-MM-DD HH:mm:ss');

        try {
            fetch(CONS.apiCruce + '/api/Users')
            .then(response => {
                statusCode = response.status; 
                return response.json();
            })
            .then(data => {
                    let mensaje = `${statusCode === 200? '🟢':'🟠'}  ${formattedDate} STATUS ${statusCode}\n`;
                    setHistory(prevHistory => mensaje + prevHistory); 
            })
            .catch(error => {
                console.error('Error al consultar la API:', error);
                let mensaje = `🟠 ${formattedDate}  NO RESPONDE! \n`;
                setHistory(prevHistory => mensaje + prevHistory); 
            });
        } catch (error) {
            let mensaje = `🟠 ${formattedDate}  NO RESPONDE! \n`;
            setHistory(prevHistory => mensaje + prevHistory); 
        }
        
    }
    

    function iniciarConsultas() {
        consultarBD(); 
        intervalRef.current = setInterval(consultarBD, 2000);
        let mensaje = `🚀Iniciando... \n`;
        setHistory(prevHistory => mensaje + prevHistory); 
    }
    
    function detenerConsultas() {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
        let mensaje = `⛔Deteniendo... \n`;
        setHistory(prevHistory => mensaje + prevHistory); 
    }

    return (
    <div className="relative z-0 w-full h-full pt-10">

    <LoginCard>

        <div className="w-full block mb-5 hgt-full">
            <textarea disabled className="text-area" value={history}>
            </textarea>
            
        </div>
        <div className="w-full block mb-5 mt-5">
            <button onClick={iniciarConsultas} className="w-full rounded block bg-blue-800 uppercase text-white py-4 transition-colors hover:bg-blue-900" style={{ backgroundColor: "#429d42" }}>INICIAR</button>
        </div>

        <div className="w-full block mb-5">
            <button onClick={detenerConsultas} className="w-full rounded block bg-blue-800 uppercase text-white py-4 transition-colors hover:bg-blue-900" style={{ backgroundColor: "#c24141" }}>DETENER</button>
        </div>

        <div>
            <small>Sistema para pruebas</small>
            <form>
                <input type="hidden" defaultValue={process.env.REACT_APP_NOT_SECRET_CODE} />
            </form>
            </div>

    </LoginCard>

    <div className="absolute object-cover w-full h-full top-0 left-0 z-0">
        <img className="object-cover w-full h-full" src={bg} alt="Transbordadora Austral Broom" />
    </div>

</div >
    )
}
